import React from 'react';
import { Link } from "gatsby"

const SubHeader = ({title}) => {
	return (
		<section className="page-header">
		  	<div className="container">      
			    <div className="row justify-content-md-center">
			      	<div className="col-md-12">
			        	<div className="breadcrumb-wrapper text-center">
							<h2>{title}</h2>
							<p><Link to="/">Home </Link>/ {title}</p>
		        		</div>
		      		</div>
		    	</div> 
		  	</div> 
		</section>
	)
}
export default SubHeader;