import React from "react";
import Link from "gatsby-link";
import {
    Collapse,
    NavbarToggler
} from "reactstrap";
import { StaticQuery, graphql } from "gatsby"

class Navigation extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.showHeaderFeature = props.showHeaderFeature;
        this.headerData = [];
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.closeNavbar = this.closeNavbar.bind(this);
        this.state = {
            collapsed: false,
            switchClass: false,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        window.addEventListener('scroll', this.handleScroll);
        
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('scroll', this.handleScroll);
    }
    handleScroll(event) {
        if(!this._isMounted)
            return
        if (window.scrollY > 10) {
            this.setState({
                switchClass: true
            });
        }
        else {
            this.setState({switchClass: false});
        }
    }

    toggleNavbar() {
        if(!this._isMounted)
            return
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    closeNavbar() {
        if(!this._isMounted)
            return
        if (this.state.collapsed !== true) {
            this.toggleNavbar();
        }
    }

    render() {
        let data = this.props.allHeaderJson.edges;
        let d = []
        data.forEach((item)=>{
            d.push(item.node);
        })
        this.headerData = d[0];
        return (
            <header id="header-wrap">
                <nav className={`navbar navbar-expand-md bg-inverse fixed-top scrolling-navbar ${this.showHeaderFeature ? (this.state.switchClass  ? 'top-nav-collapse' : '') : 'top-nav-collapse'}`}>
                    <div className="container">
                        <Link to="/" className="navbar-brand"><img src={this.headerData.logo.childImageSharp.fluid.src} alt=""/></Link>       
                        <NavbarToggler className="navbar-toggler mr-2" onClick={this.toggleNavbar}>
                            <i className="lni-menu"></i>
                        </NavbarToggler>
                        <Collapse isOpen={this.state.collapsed} className="collapse navbar-collapse" id="navbarCollapse">
                            <ul className="navbar-nav mr-auto w-100 justify-content-end clearfix">
                                {this.headerData.menus.map((item, index)=>{
                                    if(item.sameOrigin){
                                        return(
                                            <li className="nav-item" key={`link_${index}`}>
                                                <Link className="nav-link" activeClassName="active" to={item.path}>
                                                    {item.name}
                                                </Link>
                                            </li>
                                        )
                                    }
                                    else{
                                        return(
                                            <li className="nav-item" key={`link_${index}`}>
                                                <a className="nav-link"  href={item.path === 'LOGIN_URL' ? process.env.LOGIN_URL: (item.path === 'REGISTER_URL' ? process.env.REGISTER_URL : process.env.SUPPORT_URL)}>
                                                    {item.name}
                                                </a>
                                            </li>
                                        )
                                    }
                                })}
                            </ul>
                        </Collapse>
                    </div>
                </nav>
                <div id="hero-area" className="hero-area-bg" style={this.showHeaderFeature ? {} : { display: 'none' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                <div className="contents text-center">
                                    <h2 className="head-title wow fadeInUp" dangerouslySetInnerHTML={{__html: this.headerData.title}}/>
                                    <div className="header-button wow fadeInUp">
                                        <Link to="/service" className="btn btn-common">Get Started</Link>
                                    </div>
                                </div>
                                <div className="img-thumb text-center wow fadeInUp">
                                    <img className="img-fluid" src={this.headerData.image.childImageSharp.fluid.src} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default props => (
    <StaticQuery
        query={graphql`
            query {
                allHeaderJson {
                    edges {
                        node {
                            title
                            image {
                                childImageSharp {
                                    fluid {
                                        src
                                    }
                                }
                            }
                            logo {
                                childImageSharp {
                                    fluid {
                                        src
                                    }
                                }
                            }
                            menus {
                                name
                                path
                                sameOrigin
                            }
                        }
                    }
                }
            }
        `}
        render={({ allHeaderJson }) => <Navigation allHeaderJson={allHeaderJson} {...props} />}
    />
)