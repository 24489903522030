/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
//import { useStaticQuery, graphql } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';
import Bootstrap from './css/bootstrap.min.css'
import Animated from './css/animate.css'
import slickNav from './css/slicknav.css'
import magnificPopup from './css/magnific-popup.css'
import lineIcons from './fonts/line-icons.css'
import nivoLightbox from './css/nivo-lightbox.css'
import main from './css/main.css'
import responsive from './css/responsive.css'

import Header from "./header"
import Footer from "./footer"
import { Helmet } from "react-helmet"
import "./layout.css";
//import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
//import { Container, Row} from 'reactstrap';

const Layout = ({ children, headerContent=false}) => {
    // const data = useStaticQuery(graphql`
    //   query SiteTitleQuery {
    //     site {
    //       siteMetadata {
    //         title
    //       }
    //     }
    //   }
    // `)
    return (
        <>
            <Helmet>
                <title>Stack - Bootstrap 4 Business Template</title>
                <link src={Bootstrap} type="text/css"/>
                <link src={lineIcons} type="text/css"/>
                <link src={slickNav} type="text/css"/>
                <link src={magnificPopup} type="text/css"/>
                <link src={nivoLightbox} type="text/css"/>
                <link src={Animated} type="text/css"/>
                <link src={main} type="text/css"/>
                <link src={responsive} type="text/css"/>
            </Helmet>
            <Header showHeaderFeature={headerContent}/>
                {children}
            <Footer/>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
