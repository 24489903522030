import React from "react";
//import Link from "gatsby-link";
import { StaticQuery, graphql } from "gatsby"
class Navigation extends React.Component {
    constructor(props) {
        super(props);
        this.handleScroll = this.handleScroll.bind(this);
        this.state = {
            showArrow: false
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    handleScroll(){
    	if(window.scrollY > 300)
    		this.setState({showArrow: true})
    	else
    		this.setState({showArrow: false})
    }

    scrollToTop(){
    	window.screenY = 0;
    }

    render(){
        let footer = [];
        this.props.allFooterJson.edges.forEach((item)=>{
            footer.push(item.node)
        })
        let f = footer[0]
    	return (
    	<>
    		<div className="copyright">
    		  	<div className="container">
    		    	<div className="row">
    		      		<div className="col-lg-4 col-md-3 col-xs-12">
    		        		<div className="footer-logo">
    		          			<img src={f.logo.childImageSharp.fluid.src} alt=""/>
    		        		</div>
    		      		</div>
    		      		<div className="col-lg-4 col-md-4 col-xs-12">     
    		        		<div className="social-icon text-center">
                                {
                                    f.social.map((item, index)=>{
                                        return (
                                            <a className={item.icon} href={item.link} key={`key_${index}`}><i className={`lni-${item.icon}-filled`}></i></a>
                                        )
                                    })
                                }
    		        		</div>
    		      		</div>
    		      		<div className="col-lg-4 col-md-5 col-xs-12">
    		        		<p className="float-right">© {new Date().getFullYear()} Ecompim</p>
    		      		</div> 
    		    	</div>
    		  	</div>
    		</div>
    		<a href="#" className="back-to-top" style={this.state.showArrow ? {display: 'block'} : {display: 'none'}} onClick={()=>this.scrollToTop()}>
    			<i className="lni-arrow-up"></i>
    		</a>
    	</>
    	)
    }
}

export default props => (
    <StaticQuery
        query={graphql`
            query {
                allFooterJson {
                    edges {
                        node {
                            logo {
                                childImageSharp {
                                    fluid {
                                        src
                                    }
                                }
                            }
                            social {
                                link
                                icon
                            }
                        }
                    }
                }
            }
        `}
        render={({ allFooterJson }) => <Navigation allFooterJson={allFooterJson} {...props} />}
    />
)